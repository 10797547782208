import React, { useContext, useEffect } from "react";
import { withPreview } from "gatsby-source-prismic";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import { Row, Col } from "styled-bootstrap-grid";
import styled from "styled-components";

// Transitions
import { TransitionContainer } from "../components/containers/transition-container";

// Components
import { FlexContainer } from "../components/containers/flex-container";
import { Spacer } from "../components/utils/spacer";
import { tertiary } from "../components/utils/colors";

// Context
import { FooterColor } from "../components/context/footer-color";

const Page = styled.div`
	margin: 100px auto 0 auto;
`;

const Textbox = styled.div`
	max-width: 780px;
	margin: 0 auto;

	& p:last-of-type {
		margin-bottom: 0;
	}

	& p:empty {
		display: inline-block;
		margin: 0;
	}

	&.two-column-text {
		& p:first-of-type {
			margin-top: 0;
		}
	}

	@media (max-width: 1024px) {
		max-width: 590px;

		margin: 0;
	}

	@media (max-width: 700px) {
		margin: 0;
	}
`;

const OrderConfirmation = ({ data }) => {
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	return (
		<>
			<Helmet
				title={`Order Confirmation | Christian Watson`}
				meta={[
					{
						name: "og:title",
						content: `Order Confirmation | Christian Watson`,
					},
				]}
			/>
			<Page>
				<FlexContainer
					desktopPadding={`0 60px`}
					tabletPadding={`0 35px`}
					mobilePadding={`0 15px`}
					cv={true}
				>
					<TransitionContainer>
						<Row>
							<Col col={12} md={8} lg={6} xl={7}>
								<Textbox
									dangerouslySetInnerHTML={{
										__html: data.prismicOrderConfirmation.data.text.html,
									}}
								/>
							</Col>
						</Row>
					</TransitionContainer>
				</FlexContainer>
			</Page>
			<Spacer />
		</>
	);
};

export default withPreview(OrderConfirmation);

export const query = graphql`
	{
		prismicOrderConfirmation {
			data {
				title {
					html
				}
				text {
					html
				}
			}
		}
	}
`;
